import React from 'react';
import { FormControl, Select, MenuItem, SvgIcon } from '@mui/material';
import { styled } from '@mui/system';
import { stylesMaterial } from "../../styles/StylesMaterialUI";

const CountryMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex !important',
  alignItems: 'center !important',
}));

const FlagIcon = styled(SvgIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

function CountrySelect({ data, onChange }) {
  return (
    <FormControl sx={stylesMaterial.inputFormRegister} fullWidth size="small">
      <Select labelId="country-label" style={{ borderRadius: '30px', textAlign: 'left' }} onChange={onChange} required={true}>
        {data.map((country) => (
          <CountryMenuItem key={country['@id']} value={country['@id']}>
            <FlagIcon component={() => <img className='registerFormImagesFlags' src={`/flags/${country.flag}`} alt={country.name} />} viewBox="0 0 640 480" />
            <span>&nbsp;({country.dialCode}) {country.name}</span>
          </CountryMenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CountrySelect;
