import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
} from 'reactstrap';
import Grid from '@mui/material/Grid';
import "../../styles/RegisterStyles.css";
import imageWoman from "../../assets/images/imageWoman.png";
import imageMen from "../../assets/images/imageMen.png";
import { renderToString } from 'react-dom/server';

const items = [
  {
    htmlContent: renderToString(
      <Grid className='carruselContainer' container>
        <Grid item xs={3}>
          <br/>
          <p className='carruselParagraphTitle'> ¿Te gustaría <br/><b className='carruselStrongBlue'>mejorar tu</b><br/> <b className='carruselStrongYellow'>comprensión</b><br/> y <b className='carruselStrongYellow'>velocidad</b> de lectura?</p>
          <p className='carruselParagraphSubtitle'>Realiza el diagnostico y te contaremos cómo mejorar tu perfil profesional y académico</p>
        </Grid>
        <Grid item xs={7}>
          <img className='carruselImageOptionsWoman' src={imageWoman} />
        </Grid>
      </Grid>)
  },{
    htmlContent: renderToString(
      <Grid className='carruselContainer' container>
        <Grid item xs={3}>
          <p className='carruselParagraphTitle'> Captura <br/><b className='carruselStrongBlue'>mayor cantidad</b><br/> <b className='carruselStrongYellow'>de información</b><br/> con una sola mirada</p>
        </Grid>
        <Grid item xs={9}>
          <img className='carruselImageOptionsMen' src={imageMen} />
        </Grid>
      </Grid>)
  }
];

export const CarruselForm = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
      <div dangerouslySetInnerHTML={{__html: item.htmlContent}} />
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      interval={15000} //SET 15 SEG BY CHANGE
    >
      {slides}
    </Carousel>
  );
}
