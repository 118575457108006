import React, { useEffect } from 'react';

const TemplateTwo = (props) => {
    const data = props.data;
    return (
        <pre className='diagnosticTemplateTwoContainer'> 
            <img className='diagnosticTemplateTwoImageOne' src={data.picture_one} align="right"/>
            <p className='diagnosticTemplateTwoTitle'>{data.title}</p>
            <p className='diagnosticTemplateTwoDescription'>{data.description}</p>
        </pre>
    );
};

export default TemplateTwo;