import React from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom"
import { Container } from '@mui/material';
import RegisterForm from './components/RegisterView/RegisterForm';
import DiagnosticForm from './components/DiagnosticView/DiagnosticForm';
import TestingForm from './components/TestingView/TestingForm';
import ResultForm from './components/ResultView/ResultForm';
import NotFound from './components/NotFound';
import './App.css';

function App() {
  return (
    <div className="App">
      <Container>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<RegisterForm />} />
            <Route path="/diagnostic" element={<DiagnosticForm />} />
            <Route path="/testing" element={<TestingForm />} />
            <Route path="/result" element={<ResultForm />} />
            <Route path='*' exact={true} element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </Container>
    </div>
  );
}

export default App;
