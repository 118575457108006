import React, {Fragment} from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from '@mui/material/Checkbox';
import { CarruselForm } from './CarruselForm';
import CountrySelect from './CountrySelect';
import CountrySelectSearch from './CountrySelectSearch';
import { stylesMaterial } from "../../styles/StylesMaterialUI";
import { Navigate } from 'react-router-dom';
import "../../styles/RegisterStyles.css";

export default class RegisterForm extends React.Component {
  BASE_URL = process.env.REACT_APP_BASE_URL;
  FRONTEND_URL = process.env.REACT_APP_URL_FRONT_REDIRECT;
  TERMS_CONDITIONS = process.env.REACT_APP_CALL_TERMS_CONDITIONS;
  API_IP_PUBLIC = process.env.REACT_APP_API_GET_PUBLIC_IP_ADDRESS;

  constructor(props) {
    super(props);
    this.state = { 
      name: '',
      lastname: '',
      phone: '',
      email: '',
      age: '',
      academicLevel: '',
      city: '',
      country: '',
      clientIp: '',
      conditions: false,
      dataAcademicLevel:[],
      dataAge:[],
      dataCountries:[],
      dataCities:[],
      redirectToDiagnostic: false,
      flagImages: null,
    };
  }
  
  componentDidMount() {
    localStorage.clear();
    
    axios.get(this.API_IP_PUBLIC)
    .then(response => {
      this.setState({ clientIp: response.data.ip });
    })

    axios.get(`${this.BASE_URL}/academic_levels`)
    .then(resLevel => {
      this.setState({ dataAcademicLevel: resLevel.data['hydra:member'] });
    })

    axios.get(`${this.BASE_URL}/countries?pagination=false`)
    .then(async resCountries => {
      this.setState({ dataCountries: resCountries.data['hydra:member'] });
    })
  }

  handleSubmit(event) {
    event.preventDefault();
    let formData = {
      name: this.state.name,
      // lastname: this.state.lastname,
      phone: parseInt(this.state.phone),
      email: this.state.email,
      // city: this.state.city,
      country: this.state.country,
      ageRange: parseInt(this.state.age),
      academicLevel: this.state.academicLevel,
      ip: this.state.clientIp,
      conditions: this.state.conditions,
    };

    axios.post(`${this.BASE_URL}/clients`, formData)
    .then(response => {
      localStorage.setItem('user', JSON.stringify(response.data));
      // window.open(`/diagnostic`, '_blank');
      window.open(`${this.FRONTEND_URL}/diagnostic`, '_blank');
      // this.setState({ redirectToDiagnostic: true});
    })
  }

  handleCountries(event){
    this.setState({ country: event.target.value});
    // axios.get(`${this.BASE_URL}/cities?country[]=${event.target.value}&pagination=false`)
    //   .then(resCities => {
    //     this.setState({ dataCities: resCities.data['hydra:member'] });
    // })
  }

  handleCheckboxChange = (event) => {
    debugger
    this.setState({conditions: event.currentTarget.checked});
  };

  render() {
    // if (this.state.redirectToDiagnostic) {
    //   return <Navigate to="/diagnostic" />;
    // }    
    return (
      <Fragment>
          <Grid className="containerRegister" container spacing={2}>
            {/* <Grid item xs={12}> */}
              {/* <CarruselForm /> */}
            {/* </Grid> */}
            <Grid className="containerRegisterForm" container xs={12}>
              <Grid className='containerRegisterFormTitle'>
                <p className='registerFormTitle'>Empiece su diagnóstico aquí</p>
                <p className='registerFormSubtitle'>Ingrese sus datos y conozca su potencial ahora mismo</p>
              </Grid>
              <form onSubmit={eventButton => this.handleSubmit(eventButton)} autocomplete="off" >
                <Grid item xs={12} className='containerFormFields'>
                  <TextField type='text' sx={stylesMaterial.inputFormRegister}
                    className='formRegisterFields' label="Nombre" variant="outlined" 
                    required={true} size="small" fullWidth onChange={eventText => this.setState({name: eventText.target.value })} />
                  
                  {/* <TextField type='text' sx={stylesMaterial.inputFormRegister} 
                    className='formRegisterFields' label="Apellidos" variant="outlined" 
                    required={true} size="small" fullWidth onChange={eventText => this.setState({lastname: eventText.target.value })} /> */}
                  
                  <Grid container xs={12}>
                    <Grid item xs={4}>
                      <CountrySelect data={this.state.dataCountries} onChange={eventText => this.handleCountries(eventText)} flagImages={this.state.flagImages} />
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={7}>
                      <TextField type='number' sx={stylesMaterial.inputNumberFormRegister} 
                        className='formRegisterFields' label="Número de contacto" variant="outlined" 
                        required={true} size="small" fullWidth onChange={eventText => this.setState({phone: eventText.target.value })} />
                    </Grid>
                  </Grid>
                  
                  <TextField type='email' sx={stylesMaterial.inputFormRegister}
                    className='formRegisterFields' label="Correo" variant="outlined" 
                    required={true} size="small" fullWidth onChange={eventText => this.setState({email: eventText.target.value })} />
                  
                  <TextField type='number' sx={stylesMaterial.inputNumberFormRegister} 
                    className='formRegisterFields' label="Edad" variant="outlined" 
                    required={true} size="small" fullWidth onChange={eventText => this.setState({age: eventText.target.value })} />

                  <FormControl sx={stylesMaterial.inputFormRegister}  fullWidth size="small">
                    <InputLabel required={true}>Nivel Académico</InputLabel>
                    <Select
                      label="Nivel Académico"
                      onChange={eventText => this.setState({academicLevel: eventText.target.value })}
                      required={true}
                      style={{borderRadius: '30px', textAlign:'left'}}
                    >
                      {this.state.dataAcademicLevel.map((levels) => (
                        <MenuItem value={levels['@id']}>{levels.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl> 

                  {/* <FormControl sx={stylesMaterial.inputFormRegister} fullWidth size="small">
                    <Autocomplete
                      options={this.state.dataCities}
                      getOptionLabel={(city) => city.name}
                      onChange={(event, newValue) => (newValue !== null) ? this.setState({ city: newValue['@id'] }) : this.setState({ city: '' })}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Ciudad'
                          size="small"
                          name='city-select-search'
                          required
                          InputProps={{ ...params.InputProps, autocomplete: 'off' }}
                        />
                      )}
                    />
                  </FormControl> */}
                  <Grid container xs={12}>
                    <label className='formRegisterConditions'> 
                    <Checkbox required={true} checked={this.state.conditions} onChange={this.handleCheckboxChange} /> 
                    Al enviar este formulario acepta los <a href={this.TERMS_CONDITIONS}>términos y condiciones</a></label>
                  </Grid>
                  <Button type='submit' style={stylesMaterial.inputButtonFormRegister} variant="outlined">Enviar</Button>
                </Grid>
                
              </form>
            </Grid>
          </Grid>
      </Fragment>
    )
  }
}