export const stylesMaterial = {
    // REGISTER FORM
    inputFormRegister: {
        "& .MuiInputLabel-root": {color: '#474747'},
        "& .MuiOutlinedInput-root": {"& > fieldset": { borderColor: "#28416B", borderWidth:'2px', borderRadius:'30px' }},
        "& .MuiOutlinedInput-root.Mui-focused": {"& > fieldset": {borderColor: "#28416B"} },
        "& .MuiOutlinedInput-root:hover": {"& > fieldset": {borderColor: "#28416B"}}
    },
    inputNumberFormRegister: {
        "& .MuiInputLabel-root": {color: '#474747'},
        "& .MuiOutlinedInput-root": {"& > fieldset": { borderColor: "#28416B", borderWidth:'2px', borderRadius:'30px' }},
        "& .MuiOutlinedInput-root.Mui-focused": {"& > fieldset": {borderColor: "#28416B"} },
        "& .MuiOutlinedInput-root:hover": {"& > fieldset": {borderColor: "#28416B"}},
        '& input[type=number]': {'-moz-appearance': 'textfield'},
        '& input[type=number]::-webkit-outer-spin-button': {'-webkit-appearance': 'none', margin: 0},
        '& input[type=number]::-webkit-inner-spin-button': {'-webkit-appearance': 'none',margin: 0}
    },
    inputButtonFormRegister: {
        borderRadius: '30px', 
        borderColor: "#28416B", 
        borderWidth:'2px', 
        color:'#474747'
    },
    // DIAGNOSTIC FORM
    buttonDescriptionDiagnosticForm: {
        borderRadius: '30px', 
        textTransform: 'capitalize',
        padding: '0px 5px',
        '&.Mui-disabled': {
            backgroundColor: '#28416B',
            color: '#fff',
        },
    },
    buttonDiagnosticForm: {
        borderRadius: '30px', 
        textTransform: 'capitalize',
        backgroundColor: '#28416B',
    },
    testingQuestionRadioButton: {
        color: '#28416B',
        padding: '0px 5px  !important',
        '&.Mui-checked': {
            color: '#28416B',
        },
    },
    buttonTestingForm: {
        borderRadius: '30px', 
        textTransform: 'capitalize',
        backgroundColor: '#28416B',
    },
    buttonResultForm: {
        borderRadius: '10px', 
        textTransform: 'capitalize',
        backgroundColor: '#28416B',
        fontSize: '30px',
        marginTop: '-20px',
    },
  };
