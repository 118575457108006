import React, { Component, Fragment } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import { stylesMaterial } from "../../styles/StylesMaterialUI";
import "../../styles/TestingStyles.css";
import { Navigate } from 'react-router-dom';

class TestingForm extends Component {
    BASE_URL = process.env.REACT_APP_BASE_URL;

    constructor(props) {
    super(props);
    this.state = {
        formSend: [],
        dataQuestions: [],
        redirectToResult: false,
        questionSaved: 0,
    };
    }

    componentDidMount() {
    let diagnosticFilter = JSON.parse(localStorage.getItem("diagnostic"));
    let userId = JSON.parse(localStorage.getItem("user"));
    axios.get(`${this.BASE_URL}/questions?diagnostic=${diagnosticFilter['id']}&order%5BorderQuestion%5D=ASC`)
        .then(resQuestion => {
        let formSendInit = [];
        let questionData = resQuestion.data['hydra:member'];
        this.setState({ dataQuestions: questionData});
        questionData.forEach(element => {
            formSendInit.push({client: userId['@id'], question: element['@id'], answer: ''});
        });
        this.setState({ formSend: formSendInit});
        })
    }

    handleRadioChange = (event, question) => {
        this.setState((prevState) => {
            for (let iQA = 0; iQA < prevState.formSend.length; iQA++) {
                if (prevState.formSend[iQA]['question'] == question) {
                    prevState.formSend[iQA]['answer'] = event.target.value;
                }            
            }
            return({
                formSend: prevState.formSend,
            })
        });
    };

    handleSubmit = async (event) => {
        let formValidate = true;
        event.preventDefault();    
        this.state.formSend.forEach(element => {
            if(element['answer'] == ''){
                formValidate = false;
            }
        });

        if(formValidate == false){
            alert("Formulario incompleto");
            return false
        }
    
        this.state.formSend.forEach(async elementSend => {
            let formData = {
                client: elementSend['client'],
                question: elementSend['question'],
                answer: elementSend['answer'],
              };
              await axios.post(`${this.BASE_URL}/client_testings`, formData)
              .then(response => {
                this.setState((prevState) => ({
                    questionSaved: prevState.questionSaved + 1,
                }));
                this.setState({ redirectToResult: true });
              })
        });
    };

    render() {
        if (this.state.redirectToResult && this.state.questionSaved == this.state.formSend.length) {
            return <Navigate to="/result" />;
        }
        return (
            <Fragment>
                <Grid className="testingContainer" container spacing={2}>
                    <Grid item xs={12}>
                        <p className='testingTitle'>Instrucciones</p>
                        <p className='testingDescription'>Selecciona las respuestas correctas y pulsa el Botón "Resultado"</p>
                    </Grid>
                        <Grid className='testingQuestionsContainer' item xs={12}>
                            {this.state.dataQuestions.map((questions) => (
                                <FormControl className='testingQuestionLabelRadioButton'>
                                    <FormLabel className='testingQuestionLabel'>{questions['orderQuestion']}. {questions['question']}</FormLabel>
                                    <RadioGroup
                                        className='testingQuestionRadioButtonContainer'
                                        name={`answer${questions['orderQuestion']}`}
                                        onChange={(event) =>  this.handleRadioChange(event, questions['@id'])}
                                    >
                                        {questions['answers'].map((answers) => (
                                            <FormControlLabel
                                                value={answers['@id']}
                                                control={<Radio sx={stylesMaterial.testingQuestionRadioButton} />}
                                                label={answers['answer']}
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            ))}
                        </Grid>
                        <Grid item xs={12} className='testingButtonContainer'>
                            <Button className='testingButton' 
                                sx={stylesMaterial.buttonTestingForm} 
                                variant="contained" 
                                onClick={(event) => this.handleSubmit(event)}>
                                    Finalizar
                            </Button>
                        </Grid>
                </Grid>
            </Fragment>
        );
    }
}

export default TestingForm;
