import React, {Fragment} from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { stylesMaterial } from "../../styles/StylesMaterialUI";
import "../../styles/DiagnosticStyles.css";
import TemplateOne from "./TemplateOne";
import TemplateTwo from "./TemplateTwo";
import { Navigate } from 'react-router-dom';

export default class DiagnosticForm extends React.Component {
  BASE_URL = process.env.REACT_APP_BASE_URL;

  constructor(props) {
    super(props);
    this.state = {
      isRunning: false,
      time: 0,
      dataDiagnostic: [],
      redirectToTesting: false,
    };
    this.intervalRef = null;
  }
  
  componentDidMount() {
    let userData = JSON.parse(localStorage.getItem("user"));
    let ageRange = '';
    localStorage.removeItem("diagnostic");
    axios.get(`${this.BASE_URL}/getAgeRange/${userData['ageRange']}`)
    .then(resAge => {
      ageRange = resAge.data.range_id;      
      axios.get(`${this.BASE_URL}/diagnostics/custom?ageRange=${ageRange}&academicLevel=${userData['academicLevel']['id']}&clientIp=${userData['ip']}`)
      .then(resDiagnostic => {
        this.setState({ dataDiagnostic: resDiagnostic.data[0] });
        localStorage.setItem('diagnostic', JSON.stringify(resDiagnostic.data[0]));
      })
    })
  }

  startTimer = () => {
    this.setState({ isRunning: true });
    this.intervalRef = setInterval(() => {
      this.setState((prevState) => ({
        time: prevState.time + 1,
      }));
    }, 1000);
  };

  stopTimer = () => {
    localStorage.setItem('time', this.state.time);
    clearInterval(this.intervalRef);
    this.setState({ redirectToTesting: true });
  };

  formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  render() {
    const { isRunning, time } = this.state;

    if (this.state.redirectToTesting) {
      return <Navigate to="/testing" />;
    }
    return (
      <Fragment>
          <Grid className="containerDiagnostic" container spacing={2}>
            <Grid item>
              <p className='diagnosticTitle'>Inicie su diagnóstico de velocidad y comprensión</p>
              <p className='diagnosticSubtitle'>Instrucciones</p>
              <p className='diagnosticDescription'>Haga clic sobre el botón <Button disabled sx={stylesMaterial.buttonDescriptionDiagnosticForm} variant="contained">Comenzar prueba</Button>, lea el texto de la forma y velocidad a la que está acostumbrado, cuando termine, haga clic sobre el botón "Finalizar" y luego realice la prueba de comprensión.</p>
            </Grid>
            {!isRunning ? (
              <Grid className='diagnosticChronometerContainer' item xs={12}>
                <Button className='diagnosticButton' sx={stylesMaterial.buttonDiagnosticForm} variant="contained" onClick={this.startTimer}>Comenzar prueba</Button>
                <div className='diagnosticChronometer'>{this.formatTime(time)}</div>
              </Grid>
            ) : (
              <Fragment>
                <Grid className='diagnosticTesting'>
                  {this.state.dataDiagnostic && this.state.dataDiagnostic.template == "TemplateOne" &&
                    <TemplateOne data={this.state.dataDiagnostic} />
                  }
                  {this.state.dataDiagnostic && this.state.dataDiagnostic.template == "TemplateTwo" &&
                    <TemplateTwo data={this.state.dataDiagnostic} />
                  }
                </Grid>
                <Grid className='diagnosticChronometerContainer' item xs={12}>
                  <Button className='diagnosticButton' sx={stylesMaterial.buttonDiagnosticForm} variant="contained" onClick={this.stopTimer}>Finalizar</Button>
                  <div className='diagnosticChronometer'>{this.formatTime(time)}</div>
                </Grid>
              </Fragment>
            )}
          </Grid>
      </Fragment>
    )
  }
}