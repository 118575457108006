import React from 'react';
import { FormControl, Select, MenuItem, SvgIcon, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/system';
import { stylesMaterial } from "../../styles/StylesMaterialUI";

const CountryMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex !important',
  alignItems: 'center !important',
}));

const FlagIcon = styled(SvgIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

function CountrySelectSearch({ data, onChange }) {
  return (
    <FormControl sx={stylesMaterial.inputFormRegister} fullWidth size="small">
      <Autocomplete
        options={data}
        getOptionLabel={(country) => `${country.name} (${country.dialCode})`}
        getOptionSelected={(option, value) => option['@id'] === value}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            name='city-select-search'
            required
            InputProps={{ ...params.InputProps, autocomplete: 'off' }}
          />
        )}
      />
    </FormControl>
  );
}

export default CountrySelectSearch;
